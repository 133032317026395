import { HomeWrapper } from "./style";

import Hero from "../components/hero/index";
import UserCards from "../components/userCards/index";
import App from "../components/app/index";
import System from "../components/system/index";
import App2 from "../components/app2/index";
import Tariff from "../components/tariff/index";

export default function HomePage() {
  return (
    <HomeWrapper>
      <Hero />
      <System />
      <UserCards />
      <App />
      <App2 />
      <Tariff />
    </HomeWrapper>
  );
}
