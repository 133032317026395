// @ts-ignore
import SystemImg1 from "../../../../assets/icons/system1.svg";
import SystemImg2 from "../../../../assets/icons/system2.svg";
import SystemImg3 from "../../../../assets/icons/system3.svg";
import SystemImg4 from "../../../../assets/icons/system4.svg";
import SystemImg5 from "../../../../assets/icons/system5.svg";
import SystemImg6 from "../../../../assets/icons/system6.svg";

export const data = [
    {
        img: SystemImg1,
        title: "osonsot-client.user-cards.USER_CARD_ONE.TITLE",
        subTitle: "osonsot-client.user-cards.USER_CARD_ONE.SUBTITLE",
        text: "osonsot-client.user-cards.USER_CARD_ONE.TEXT"
    },{
        img: SystemImg2,
        title: "osonsot-client.user-cards.USER_CARD_TWO.TITLE",
        subTitle: "osonsot-client.user-cards.USER_CARD_TWO.SUBTITLE",
        text: "osonsot-client.user-cards.USER_CARD_TWO.TEXT"
    },{
        img: SystemImg3,
        title: "osonsot-client.user-cards.USER_CARD_THREE.TITLE",
        subTitle: "osonsot-client.user-cards.USER_CARD_THREE.SUBTITLE",
        text: "osonsot-client.user-cards.USER_CARD_THREE.TEXT"
    },{
        img: SystemImg4,
        title: "osonsot-client.user-cards.USER_CARD_FOUR.TITLE",
        subTitle: "osonsot-client.user-cards.USER_CARD_FOUR.SUBTITLE",
        text: "osonsot-client.user-cards.USER_CARD_FOUR.TEXT"
    },{
        img: SystemImg5,
        title: "osonsot-client.user-cards.USER_CARD_FIVE.TITLE",
        subTitle: "osonsot-client.user-cards.USER_CARD_FIVE.SUBTITLE",
        text: "osonsot-client.user-cards.USER_CARD_FIVE.TEXT"
    },{
        img: SystemImg6,
        title: "osonsot-client.user-cards.USER_CARD_SIX.TITLE",
        subTitle: "osonsot-client.user-cards.USER_CARD_SIX.SUBTITLE",
        text: "osonsot-client.user-cards.USER_CARD_SIX.TEXT"
    },

];