import { Suspense } from 'react';
import { Toaster } from 'react-hot-toast';
import { ThemeProvider } from "styled-components";
import Footer from './containers/footer';
import Header from './containers/header';
import Home from './pages/home/container/index';
import { LightTheme } from "./styles/theme/lightTheme";


function App() {
    return (
        <ThemeProvider theme={LightTheme}>
            <Suspense fallback={<div>Loading ...</div>}>
                <Header />
                <Home />
                <Footer />
            </Suspense>
            <Toaster />
        </ThemeProvider>
    )
}

export default App