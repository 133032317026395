import styled from 'styled-components'
import { colors } from '../../../../assets/styles/colors'

export const AppWrapper = styled.div`
    background-color: ${colors.disabledGray};
    margin: 50px 0;
    padding: 50px 0;
    img{
      width: 100%;
    }

    & .appTitle {
        font-style: normal;
        font-weight: bold;
        font-size: 36px;
        line-height: 120%;
        color: ${colors.black};
        letter-spacing: 2px;
        span{
          color: ${colors.mainBlue};
        }

        @media (max-width: 992px) {
            font-size: 33px;
        }

        @media (max-width: 768px) {
            font-size: 44px;
        }

        @media (max-width: 375px) {
            font-size: 32px;
        }
    }

.appText {
    .appList {
        margin-top: 20px;
        .appListItem {
            margin-bottom: 5px;
            font-style: normal;
            font-weight: normal;
            font-size: 16px;
            line-height: 150%;
            color: ${colors.black};
        }
    }
}

@media (max-width: 996px){
  .container{
    .row{
      flex-direction: row !important;
      .col-6{
        min-width: 90% !important;
        margin-left: 5%;
        &:nth-child(1){
          justify-content: center;
        }
        &:nth-child(2){
          display: block !important;
        }
        //justify-content: center !important;
        .appTitle{
          margin-top: 40px;
          text-align: center;
        }
      }
    }
  }
}
`