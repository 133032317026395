import styled from "styled-components";
import { colors } from "../../../../assets/styles/colors";

export const SystemWrapper = styled.div`
  .systemInner {
    .systemTitle {
      /* margin-top: 35px; */
      font-style: normal;
      font-weight: normal;
      font-size: 36px;
      text-align: center;
      margin-left: auto;
      margin-right: auto;
      span {
        color: ${colors.mainBlue};
      }
      @media (max-width: 576px) {
        font-size: 25px;
      }
    }
  }

  .systemcardWrapper {
    margin-top: 50px;

    .systemcard {
      box-shadow: 0px 0px 4px rgba(62, 177, 200, 0.2);
      border-radius: 8px;
      padding: 24px;
      height: 100% !important;
      box-sizing: border-box;
      transition: all ease 0.3s;
      cursor: pointer;

      &:hover {
        transform: scale(1.05);
        box-shadow: 0px 0px 4px rgba(62, 177, 200, 0.5);
      }
    }

    .systemcardTop {
      display: flex;
      align-items: center;

      .systemcardTitle {
        font-style: normal;
        font-weight: bold;
        font-size: 20px;
        line-height: 160%;
        margin-left: 24px;
      }
    }

    .systemcardBottom {
      margin-top: 32px;
    }
  }

  @media (max-width: 576px) {
    margin-top: 50px;
  }
`;
