import 'bootstrap/dist/css/bootstrap.min.css'
import { useRef, useState } from 'react'
// import { useTranslation } from 'react-i18next'
import { useTranslation } from 'react-i18next'
import Logo from '../../assets/icons/logo.png'
import EnglandFlag from '../../assets/images/england_flag.png'
import RussianFlag from '../../assets/images/russian_flag.png'
import UzbFlag from '../../assets/images/uzb_flag.png'
import { colors } from '../../assets/styles/colors'
import Button from '../../components/button/index'
import ModalComponent from '../../components/modal'
import useOutsideHandler from '../../hooks/main'
import { DropdownItem, DropdownItemBlock, DropdownStyle, DropdownTitle, HeaderWrapper } from './style'

const Header = () => {
    const [isOpen, setIsOpen] = useState(false);
    const { t, i18n } = useTranslation();
    const [show, setShow] = useState(false);
    const outsideOfSelectRef = useRef<any>()
    useOutsideHandler(outsideOfSelectRef, setShow);
    const [lng, setLng] = useState(localStorage.getItem("i18nextLng"));

    const changeLanguage = (language: string) => {
        i18n.changeLanguage(language);
        setLng(language);
        setShow(false)
    }


    return (
        <HeaderWrapper>
            <div className="headerInner container">
                <div className="headerInnerLeft">
                    <a href="#" className="logo">
                        <img className='logoImg' src={Logo} alt="logo-img" />
                    </a>
                </div>
                <div className="headerInnerRight">
                    <div className="dropdown">
                        <DropdownStyle ref={outsideOfSelectRef} >
                            <DropdownTitle onClick={() => setShow(!show)}>
                                {lng === "uz" ? (
                                    <div className='d-flex align-items-center'>
                                        <img src={UzbFlag} alt="" className={"me-2"} style={{ borderRadius: "50%" }} width={25} height={25} />
                                        O'zbek
                                    </div>
                                ) : lng === "ru" ? (
                                    <div className='d-flex align-items-center'>
                                        <img src={RussianFlag} alt="" className={"me-2"} style={{ borderRadius: "50%" }} width={25} height={25} />
                                        Русский
                                    </div>
                                ) : (
                                    <div className='d-flex align-items-center'>
                                        <img src={EnglandFlag} alt="" className={"me-2"} style={{ borderRadius: "50%" }} width={25} height={25} />
                                        English
                                    </div>
                                )}
                                <span className={`ms-2 ${show ? "show" : ""}`}>
                                    <svg width="15" height="8" viewBox="0 0 10 6" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M1.5575 0.434937L5 3.87744L8.4425 0.434937L9.5 1.49994L5 5.99994L0.5 1.49994L1.5575 0.434937Z" fill="#313649" />
                                    </svg>
                                </span>
                            </DropdownTitle>
                            <DropdownItemBlock className={show ? "show" : ""}>
                                <DropdownItem onClick={() => changeLanguage("uz")}>
                                    <div className='d-flex align-items-center'>
                                        <img src={UzbFlag} alt="" className={"me-2"} style={{ borderRadius: "50%" }} width={25} height={25} />
                                        O'zbek
                                    </div>
                                </DropdownItem>
                                <DropdownItem onClick={() => changeLanguage("ru")}>
                                    <div className='d-flex align-items-center'>
                                        <img src={RussianFlag} alt="" className={"me-2"} style={{ borderRadius: "50%" }} width={25} height={25} />
                                        Русский
                                    </div>
                                </DropdownItem>
                                <DropdownItem onClick={() => changeLanguage("en")}>
                                    <div className='d-flex align-items-center'>
                                        <img src={EnglandFlag} alt="" className={"me-2"} style={{ borderRadius: "50%" }} width={25} height={25} />
                                        English
                                    </div>
                                </DropdownItem>
                            </DropdownItemBlock>
                        </DropdownStyle>
                    </div>
                    <Button
                        className="headerBtn"
                        name={t("osonsot-client.navbar.order")}
                        propsBackGround={colors.mainBlue}
                        propsColor={colors.white}
                        onClick={() => setIsOpen(true)}
                    />
                </div>
            </div>

            <ModalComponent
                isOpen={isOpen}
                setIsOpen={setIsOpen}
            />
        </HeaderWrapper>
    )
}

export default Header