import { useTranslation } from 'react-i18next';
import { cardData } from "./cardData";
import { SystemWrapper } from './style';


export default function System() {

    const { t, i18n } = useTranslation()

    return (
        <SystemWrapper>
            <div className="systemInner container">
                <h2 className="systemTitle">
                    {t("osonsot-client.system.title.START")} <span> {t("osonsot-client.system.title.MIDDLE")}</span>  {t("osonsot-client.system.title.END")}
                </h2>

                <div className="systemcardWrapper row">
                    {
                        cardData.map((item: any, index: number) => {
                            return <div key={index.toString()} className="col-12 col-sm-12 col-md-6 col-xl-4 mt-4">
                                <div className="systemcard">
                                    <div className="systemcardTop">
                                        <img src={item.img} alt="" className="systemcardImg" />
                                        <p className="systemcardTitle">
                                            {t([item.title])}
                                        </p>
                                    </div>
                                </div>
                            </div>
                        })
                    }
                </div>
            </div>
        </SystemWrapper>
    )
}