import styled from "styled-components"
import { colors } from '../../../../assets/styles/colors'
import checked from '../../../../assets/icons/checked.svg'

export const TariffWrapper = styled.div`

.carouselWrapper {
    overflow: hidden;
}

.tariff {
    padding-top: 70px;
}

.newsSectionTitle {
    font-style: normal;
    font-weight: bold;
    font-size: 36px;
    line-height: 135%;
    text-align: center;
    margin-top: 50px;
}

.newsSectionSubTitle {
    text-align: center;
    margin-top: 15px;
    margin-bottom: 50px;
    font-size: 20px
}

.sliderWrap {
    margin: 0 auto;
}

.tariffInner {
    .tariffTitle {
        font-style: normal;
        font-weight: bold;
        font-size: 40px;
        line-height: 135%;
        text-transform: capitalize;
        text-align: center;
    }

    .tariffSubtitle {
        font-style: normal;
        font-weight: normal;
        font-size: 16px;
        line-height: 160%;
        text-align: center;
        margin-top: 18px;
    }
}

.tariffCard {
    padding: 35px 73px;
    align-items: center;
    background: #FFFFFF;
    border: 2px solid #3eb1c828;
    box-sizing: border-box;
    border-radius: 10px;
    .tariffCardTitle {
        text-transform: uppercase;
        font-style: normal;
        font-weight: normal;
        font-size: 20px;
        line-height: 150%;
        text-align: center;
    }

    .tariffCardSubtitle {
        font-style: normal;
        font-weight: normal;
        font-size: 13px;
        line-height: 160%;
        text-align: center;
        margin-top: 19px;
    }

    .tariffCardPrice {
        font-style: normal;
        font-weight: normal;
        font-size: 39px;
        line-height: 135%;
        text-align: center;
        margin-top: 23px;
    }

    .tariffPeriod {
        width: 100%;
        background-color: ${colors.disabledGray};
        padding: 12px 35px;
        border-radius: 18px;
        /* overflow: hidden; */
        position: relative;
        border: 2px solid ${colors.disabledGray};
        transition: all ease 0.3s;
        margin-top: 40px;
        margin-bottom: 40px;

        .tariffPeriodType {
            cursor: pointer;
            font-style: normal;
            font-weight: normal;
            font-size: 14px;
            line-height: 160%;
            color: ${colors.black};
            z-index: 2;
        }

        .dynamicBtn {
            transition: all ease 0.3s;
            position: absolute;
            width: 50%;
            height: 99%;
            border-radius: 18px;
            background-color: ${colors.white};
            top: 0;
            left: 0;
            z-index: 1;
        }

        .activeRight {
            transform: translateX(100%);
        }
        .activeLeft {
            transform: translateX(0%);
        }
    }

    .tariffCardOpportunities {
        font-style: normal;
        font-weight: normal;
        font-size: 16px;
        line-height: 160%;
        margin-top: 30px;
        text-align:center;
        &:before {
            content: '';
            background-repeat: no-repeat;
            background-size: contain;
            background-position: center center;
            position: absolute;
            width: 27px;
            height: 27px;
            top: 0;
            left: -35px;
        }
    }

    .tariffCardBtn {
        font-style: normal;
        font-weight: normal;
        font-size: 16px;
        line-height: 120%;
        text-align: center;
        margin-top: 33px;
        background-color: ${colors.mainBlue};
        padding: 17px 56px;
        display: block;
        border-radius: 4px;
        color: ${colors.white};
        cursor: pointer;
        transition: all ease 0.3s;

        &:hover {
            opacity: 0.8;
        }
    }

    @media (max-width: 1200px) and (min-width: 992px){
        padding: 20px 50px;

        .tariffCardSubtitle {
            margin-top: 8px;
        }

        .tariffCardPrice {
            margin-top: 12px;
        }

        .tariffPeriod {
            margin-top: 20px;
            padding: 8px 25px;


            .tariffPeriodType {
                font-size: 12px;
            }
        }

        .tariffCardOpportunities {
            font-size: 14px;
            margin-top: 15px;
        }

        .tariffCardBtn {
            padding: 12px 56px;
            font-size: 13px;

        }
    }

    @media (max-width: 375px){
        .tariffCardSubtitle {
            margin-top: 5px;
        }

        .tariffCardPrice {
            margin-top: 8px;
        }

        .tariffPeriod {
            margin-top: 15px;
            padding: 8px 25px;


            .tariffPeriodType {
                font-size: 10px;
            }
        }

        .tariffCardOpportunities {
            font-size: 12px;
            margin-top: 12px;

        }

        .tariffCardBtn {
            padding: 10px 56px;
            font-size: 11px;

        }
    }

    @media (max-width: 375px){

        .tariffPeriod {
            margin-top: 15px;
            padding: 8px 13px;


            .tariffPeriodType {
                font-size: 10px;
            }
        }

    }
    

}
@media (max-width: 992px){
  .sliderMedia{
    display: block;
  }
}
@media (min-width: 992px){
  .sliderMedia{
    display: none;
  }
}

@media (max-width: 767px){
  .sliderMedia{
  .col-6{
    min-width: 80% !important;
   
  }
  .offset-3{
      margin-left: 10% !important;
    }
  }
}

@media (max-width: 576px){
  .sliderMedia {
    .tariffCard{
    padding-left: 20px;
    padding-right: 20px;
    .paddingMedia{
      padding: 0 40px;
    }
      
    }
  }
}
`;