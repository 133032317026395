import { Button } from "./style"

export default function ButtonComponent(props: any) {
    const { name, propsBackGround, propsColor, onClick, className } = props

    return (
        <Button
            className={className}
            propsBackGround={propsBackGround}
            propsColor={propsColor}
            onClick={onClick}
        >
            {name}
        </Button>
    )
}