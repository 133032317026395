import { useTranslation } from "react-i18next";
import Logo from "../../assets/icons/logo.png";
import Gmail from "../../assets/images/email.svg";
import Facebook from "../../assets/images/facebook.svg";
import Instagram from "../../assets/images/instagram.svg";
import Location from "../../assets/images/location.svg";
import Phone from "../../assets/images/phone.svg";
import Telegram from "../../assets/images/telegram.svg";
import Twitter from "../../assets/images/twitter.svg";
import { FooterWrapper } from "./style";

function Footer() {
  const { t, i18n } = useTranslation();

  return (
    <FooterWrapper>
      <div className="container">
        <div className="row py-5">
          <div className="col-3">
            <img src={Logo} alt="" />
          </div>
          <div className="col-2">
            <div>
              <div className="d-flex">
                <img src={Phone} alt="" />
                <p className="ms-2">{t("osonsot-client.footer.PHONE")}</p>
              </div>
              <a href="tel:+998994958186">+998 (99) 4958186</a>
            </div>
          </div>
          <div className="col-2">
            <div>
              <div className="d-flex">
                <img src={Gmail} alt="" />
                <p className="ms-2">{t("osonsot-client.footer.GMAIL")}</p>
              </div>
              <a href={"mailto:info@osonsot.uz"}>info@osonsot.uz</a>
            </div>
          </div>
          <div className="col">
            <div>
              <div className="d-flex">
                <img src={Location} alt="" />
                <p className="ms-2">{t("osonsot-client.footer.LOCATION")}</p>
              </div>
              <p
                className="location"
                style={{ cursor: "pointer" }}
                onClick={() =>
                  window.open(
                    "https://maps.google.com?q=" +
                      41.355537330890826 +
                      "," +
                      69.28798409762611
                  )
                }
              >
                {t("osonsot-client.footer.LOCATION_TEXT")}
              </p>
            </div>
          </div>
          <div className="col">
            <div>
              <p>{t("osonsot-client.footer.SOCIAL_MEDIA")}</p>
              <div className="d-flex align-items-center">
                <a
                  href="http://www.facebook.com/Unical-Solutions-104111662133943"
                  target="_blank"
                >
                  <img src={Facebook} alt="" width={35} height={35} />
                </a>
                <a href="https://t.me/kahero" target="_blank">
                  <img
                    src={Telegram}
                    alt=""
                    width={35}
                    height={35}
                    className="mx-3"
                  />
                </a>
                <a
                  href="http://www.instagram.com/unicalsolutions"
                  target="_blank"
                >
                  <img src={Instagram} alt="" width={35} height={35} />
                </a>
                <a
                  href="http://www.instagram.com/unicalsolutions"
                  target="_blank"
                >
                  <img
                    src={Twitter}
                    alt=""
                    width={35}
                    height={35}
                    className="mx-3"
                  />
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="license" />
      <div className="container">
        <p className="license-text">{t("osonsot-client.footer.LICENSE")}</p>
      </div>
    </FooterWrapper>
  );
}

export default Footer;
