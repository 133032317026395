import ModalComponent from '../../../../components/modal'

import { HeroWrapper } from './style'
import { colors } from '../../../../assets/styles/colors'
import Button from '../../../../components/button/index'

// import './main.css'
import { useState } from 'react'

import { useTranslation } from 'react-i18next'

import HeroImage from "../../../../assets/images/hero-img.svg"

export default function Hero() {
    const [isOpen, setIsOpen] = useState(false)
    const { t } = useTranslation()
    return (
        <HeroWrapper>
            <div className="container">
                <div className="row reverse">
                    <div className="col-6 heroInner d-flex align-items-center">
                        <div>
                            <h1 className="heroTitle">
                                {t("osonsot-client.hero.title")}
                            </h1>
                            <p className="heroSubtitle">
                                {t("osonsot-client.hero.subtitle")}
                            </p>
                            <Button
                                className="heroBtn"
                                name={t("osonsot-client.hero.button")}
                                propsBackGround={colors.white}
                                propsColor={colors.mainBlue}
                                onClick={() => setIsOpen(true)}
                            />
                        </div>
                    </div>
                    <div className="col-6 d-flex align-items-center justify-content-center">
                        <img src={HeroImage} alt="hero" />
                    </div>
                </div>
            </div>
            <ModalComponent
                isOpen={isOpen}
                setIsOpen={setIsOpen}
            />
        </HeroWrapper>
    )
}