import axios from 'axios'
import { useState } from 'react'
import Modal from "react-bootstrap/Modal"
import { useForm } from 'react-hook-form'
import toast from 'react-hot-toast'
import { useTranslation } from 'react-i18next'
import Logo from '../../assets/icons/logo.png'
import { ModalWrapper } from './style'



interface UserDataType {
  name: string;
  phone: string
}

interface Register {
  firstName: string
  phoneNumber: string
}

export default function ModalComponent(props: any) {

  const { t, i18n } = useTranslation()

  const { isOpen, setIsOpen } = props

  const hideModal = () => {
    setIsOpen(false)
  }

  const [userData, setUserData] = useState<UserDataType>()

  const { register, handleSubmit, reset, formState: { errors } } = useForm<Register>()

  const url = `https://api.telegram.org/bot5027389957:AAGgMGVmmmM2ZNViw0XEI9ZGCbIQZQUbBk0/sendMessage?chat_id=-1001658024258&text=Name : ${userData?.name}\nNumber: +998${userData?.phone}\nMessage: "Hello"`;

  const handlChangePhone = (e: any) => {
    if (e.target.value.startsWith("+998")) {
      setUserData((prev: any) => ({ ...prev, phone: e.target.value.substring(4) }))
    } else {
      setUserData((prev: any) => ({ ...prev, phone: e.target.value }))
    }
  }

  const onSubmit = () => {

    if (userData?.name && userData?.phone) {
      try {
        axios.get(
          encodeURI(url)
        )
        setUserData({
          name: '',
          phone: ''
        })
        toast.success("Operator siz bilan bog'lanishini kuting")

        setIsOpen(false)
        reset()

      } catch (err) {
        console.log(err);
        toast.error('Xatolik yuz berdi')
      }
    }
  }

  return (
    <Modal show={isOpen} onHide={hideModal}>
      <Modal.Body>
        <ModalWrapper>
          <div>
            <div>
              <div>
                <div className="p-5 pb-0 text-center">
                  <img src={Logo} alt="logo-image" className="modal-logo pb-4" />
                  <h5 className="modal-title text-center" id="exampleModalLabel">
                    {t("osonsot-client.modal.title")}
                  </h5>
                </div>
                <div className="p-5 pb-2">
                  <form onSubmit={handleSubmit(onSubmit)}>
                    <div className="mb-3 modal-tel-input-wrap">
                      <input {...register("firstName", { required: true, minLength: 3, maxLength: 64 })} type="text" className="form-control modal-input" id="recipient-name" value={userData?.name} onChange={(e: any) => setUserData((prev: any) => ({ ...prev, name: e.target.value }))} placeholder={t("osonsot-client.modal.placeholder")} />
                      <div className="errorWrapper">
                        {console.log(errors)}

                        {!!errors.firstName && <p className="errorMessage">Ism noto'g'ri kiritilgan</p>}
                      </div>
                    </div>
                    <div className="mb-3 modal-tel-input-wrap">
                      <input {...register("phoneNumber", { required: true, minLength: 9, maxLength: 9 })} type="tel" className="form-control modal-input modal-tel-input" value={userData?.phone} id="recipient-name" placeholder="_ _ _ _ _" onChange={handlChangePhone} />
                      <div className="errorWrapper">
                        {!!errors.phoneNumber && <p className="errorMessage">Raqam noto'g'ri kiritilgan</p>}
                      </div>
                      <p className="modal-tel-num">+998</p>
                    </div>
                    <div className="p-5 pt-0">
                      <button type="submit" className="btn w-100 modal-btn" >{t("osonsot-client.modal.button")}</button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </ModalWrapper>
      </Modal.Body>
    </Modal>
  )
}