import styled from 'styled-components'
import { colors } from '../../assets/styles/colors'

export const FooterWrapper = styled.div`
    background-color: ${colors.black};
    margin-top: 60px;
    p{
      color: ${colors.white};
      font-size: 18px;
    }
    a{
      color: ${colors.white};
      text-decoration: none;
    }

.license-text {
            font-style: normal;
            font-weight: normal;
            font-size: 16px;
            line-height: 150%;
            color: ${colors.white};
            text-align: start;
            padding: 11px 0;
        }
    .license {
        border-top: 1px solid rgba(255, 255, 255, 0.096);
    }

@media (max-width: 996px){
  .container{
  .license-text{
    text-align: center;
  }
  .location{
    text-align: center;
  }
    .row{
      flex-direction: row !important;
      .col-3, .col-2, .col{
      margin-top: 25px;
        min-width: 100% !important;
        display: flex;
        align-items: center;
        justify-content: center;
        .d-flex{
          justify-content: center;
        }
      }
    }
  }
}
`