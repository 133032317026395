import styled from 'styled-components';

export const HeaderWrapper = styled.div`

.headerInner {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 72px;

    & .headerInnerRight {
        display: flex;
        align-items: center;
        .dropdown{
          @media (max-width: 767px){
          margin-right: 0 !important;
        }
        }
    }
}

.logo {
    transition: all ease 0.3s;
    &:hover {
        opacity: 0.6;
    }
}

.dropdown {
    margin-right: 50px;
    margin-bottom: -3px;
    width: auto !important;
    box-sizing: border-box;
    // .show{
    //     min-width: 150px
    // }
    .select {
        font-style: normal;
        font-weight: normal;
        font-size: 16px;
        line-height: 24px;
        padding-right: 10px;
        position: relative;

        &::after {
            content: "";
            position: absolute;
            background-image: url('../../images/icons/ddarrow.svg');
            background-repeat: no-repeat;
            background-size: cover;
            width: 10px;
            height: 6px;
            top: 9px;
            right: 0;

        }
    }
}

.headerSelect {
    border: none;

    &:focus {
        outline: none;
    }
}

.headerSelect#xyz #options {
    border: none;
    outline: none;
}

@media only screen and (max-width: 576px){
    .logo {
        .logoImg {
            width: 152px;
            height: 32px;
        }
        
    }
}

@media only screen and (max-width: 375px){
    .dropdown {
        margin-left: 0;
    }
}

    .headerSelect {
        border: none;
        cursor: pointer;

        &:focus {
            outline: none;
        }
    }
`

export const StyleSelectAll = styled.div`
  margin-top: 10px;
  .css-1s2u09g-control {
    border-radius: 8px;
    border: none;
  }
  .css-319lph-ValueContainer {
    height: 56px;
    padding-left: 16px;
  }
  .css-1hb7zxy-IndicatorsContainer span {
    display: none;
  }
  .react-select-3-live-region {
    border: 5px;
  }
  .css-1pahdxg-control {
    border-color: transparent !important;
    box-shadow: none!important;
    border-radius: 8px;

    &:hover {
        border-color: transparent !important;
    }

    &:focus {
        border-color: transparent !important;
    }
  }

`;

export const DropdownStyle = styled.div`
    position: relative;
`
export const DropdownTitle = styled.div`
    cursor: pointer;
    padding: 10px;
    display: flex;
    alignt-items: center;
    span {
        margin-left: 10px !important;
        transition: all ease 0.2s;
        &.show svg {
            transform: rotate(-180deg) !important;
            transition: all 0.2s ease-in-out;
        }
    }
`
export const DropdownItemBlock = styled.div`
    display: block;
    position: absolute;
    display: none;
    top: calc(100% + 10px);
    right: 0 !important;
    background: #fff;
    box-shadow: 0px 0px 4px rgba(2, 2, 2, 0.12);
    border-radius: 8px;
    padding: 8px 0;
    &.show {
        display: block;
    }
`
export const DropdownItem = styled.div`
    // width: 100px;
    padding: 3px 10px;
    cursor: pointer;
    &:hover {
        background: #F3FBFE;
    }
`
