import UseImageShirt from "../../../../assets/images/user-image-shirt.svg"
import UseImageBurger from "../../../../assets/images/user-image-burger.svg"
import UseImageCosmetic from "../../../../assets/images/user-image-cosmetic.svg"
import UseImageAccesuar from "../../../../assets/images/user-image-accesuar.svg"
import UseImageElectronical from "../../../../assets/images/user-image-electronical.svg"
import UseImageFurniture from "../../../../assets/images/user-image-furniture.svg"

export const cardData = [
    {
        img: UseImageShirt,
        title: "osonsot-client.system.CARDS.CARD_ONE.TITLE"
    },
    {
        img: UseImageBurger,
        title: "osonsot-client.system.CARDS.CARD_TWO.TITLE"
    },
    {
        img: UseImageCosmetic,
        title: "osonsot-client.system.CARDS.CARD_THREE.TITLE"
    },
    {
        img: UseImageAccesuar,
        title: "osonsot-client.system.CARDS.CARD_FOUR.TITLE"
    },
    {
        img: UseImageElectronical,
        title: "osonsot-client.system.CARDS.CARD_FIVE.TITLE"
    },
    {
        img: UseImageFurniture,
        title: "osonsot-client.system.CARDS.CARD_SIX.TITLE"
    },
];