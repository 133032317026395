import { useTranslation } from 'react-i18next'
import Cash from '../../../../assets/images/cash.svg'
import { AppWrapper } from './style'


// import './main.css'

export default function App() {

    const { t, i18n } = useTranslation()

    return (
        <AppWrapper>
            <div className="appInner container">
                <div className="row">
                    <div className="col-6 d-flex align-items-center">
                        <img src={Cash} alt="image" />
                    </div>
                    <div className="col-6 d-flex align-items-center">
                        <div>
                            <h2 className="appTitle">
                                <span>{t("osonsot-client.app.title.START")}</span> {t("osonsot-client.app.title.END")}
                            </h2>
                            <div className="appText">
                                <ul className="appList">
                                    <li className="appListItem">
                                        {t("osonsot-client.app.item1")}
                                    </li>
                                    <li className="appListItem">
                                        {t("osonsot-client.app.item2")}
                                    </li>
                                    <li className="appListItem">
                                        {t("osonsot-client.app.item3")}
                                    </li>
                                    <li className="appListItem">
                                        {t("osonsot-client.app.item4")}
                                    </li>
                                    <li className="appListItem">
                                        {t("osonsot-client.app.item5")}
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </AppWrapper>
    )
}