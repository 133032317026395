import { useTranslation } from "react-i18next";
import { data } from "./cardData";
import { UserCardsWrapper } from "./style";

export default function UserCards() {
  const { t, i18n } = useTranslation();

  return (
    <UserCardsWrapper>
      <div className="userCardsInner container">
        <h1 className="title" style={{ marginTop: "150px" }}>
          {t("osonsot-client.user-cards.title.START")}{" "}
          <span>{t("osonsot-client.user-cards.title.MIDDLE")}</span>{" "}
          {t("osonsot-client.user-cards.title.END")}
        </h1>
        <p className="text">
          {t("osonsot-client.user-cards.text.START")}
          <span> {t("osonsot-client.user-cards.text.END")}</span>
        </p>
        <div className="userCardWrapper row">
          {data.map((item: any, index: number) => {
            return (
              <div
                key={index.toString()}
                className="col-12 col-sm-12 col-md-6 col-xl-4 mt-4"
              >
                <div className="userCard">
                  <div className="userCardTop">
                    <img src={item.img} alt="" className="userCardImg" />
                    <p className="userCardTitle">{t([item.title])}</p>
                  </div>
                  <div className="headersubtitle mt-4">
                    {t([item.subTitle])}
                  </div>
                  <ul className="userCardBottom px-2">
                    {t([item.text])
                      .split("\n")
                      .map((text) => {
                        return <li className="userCardText">{text}</li>;
                      })}
                  </ul>
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </UserCardsWrapper>
  );
}
