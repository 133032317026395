import styled from 'styled-components'
import { colors } from '../../../../assets/styles/colors'

export const HeroWrapper = styled.div`
    padding: 50px 0 150px 0;
    img{
      width: 100%;
    }
    & .heroTitle {
        font-style: normal;
        font-weight: bold;
        font-size: 43px;
        color: ${colors.black};
        letter-spacing: 2px;
    }
    & .heroTitleColor{
      color:  ${colors.mainBlue};
    }

    & .heroSubtitle {
        font-style: normal;
        font-weight: lighter;
        font-size: 16px;
        line-height: 32px;
        color: ${colors.black};
        margin-top: 16px;
    }

    & .heroBtn {
        margin-top: 50px;
        background-color:  ${colors.mainBlue};
        color: ${colors.white};

        @media (max-width: 768px) {
            display: block;
            margin: 0 auto;
            margin-top: 72px;
        }
    }
    
    @media (max-width: 996px){
     .container{
       .reverse{
        flex-direction: column-reverse !important;
        .col-6{
          min-width: 100% !important;
          .heroTitle{
            margin-top: 40px;
            margin-bottom: 20px;
          }
          .heroTitle, .heroSubtitle{
            text-align: center;
          }
          .heroBtn{
            margin: 50px auto 0 auto;
          }
       
        }
      }
     }
    }
    @media (max-width: 576px){
      .heroTitle{
        font-size: 25px !important;
      }
      .heroTitleColor{
          margin-top: 0 !important;
      }
    }
`