import styled from "styled-components";

interface PropsType {
    propsBackGround: string;
    propsColor: string;
}

export const Button = styled.button`
    background-color: ${(props: PropsType) => props.propsBackGround};
    color: ${(props: PropsType) => props.propsColor};
    display: block;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 120%;
    text-align: center;
    padding: 13px 23px;
    border-radius: 30px;
    transition: all ease 0.3s;
    outline: none;
    border: none;


    &:hover {
        transform: scale(1.05);
        box-shadow: 0px 5px 15px -4px rgba(0, 0, 0, 0.5);
    }

    @media (max-width: 768px) {
        display: none;
    }

`
