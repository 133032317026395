import styled from 'styled-components'
import { colors } from '../../assets/styles/colors'

export const ModalWrapper = styled.div`
    .modal-btn {
        background-color: ${colors.mainBlue};
        color: ${colors.white};
        padding: 16px;
        transition: all ease 0.3s;

        &:hover {
            opacity: 0.8;
        }

        &:focus {
            box-shadow: none;
        }
    }

.modal-tel-input-wrap {
    position: relative;

    .errorWrapper {
        margin-top: 12px;
        height: 24px;

        .errorMessage {
            font-style: normal;
            font-weight: normal;
            font-size: 14px;
            line-height: 24px;
            color: #EC1212;
            position: relative;
        }
    }

    .modal-tel-input {
        padding-left: 57px !important;
    }

    .modal-tel-num {
        position: absolute;
        left: 17px;
        top: 17px;

    }
    
}

.modal-input {
    padding: 16px;
    &:focus {
        box-shadow: none;
    }
}
` 