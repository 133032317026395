import styled from 'styled-components'
import {colors} from '../../../../assets/styles/colors'

export const AppWrapper = styled.div`
    background-color: ${colors.black};
    margin: 50px 0;
    padding: 50px 0;
  

    .mobileApp{
      width: 70%;
    }
    .appTitle2 {
        font-style: normal;
        font-weight: bold;
        font-size: 36px;
        line-height: 120%;
        color: ${colors.white};
        opacity: 0.7;
        letter-spacing: 2px;
        @media (max-width: 576px){
        font-size: 25px;
        }
    }

    .appText2 {
       
        .appDefText {
            font-style: normal;
            font-weight: normal;
            font-size: 20px;
            line-height: 120%;
            margin-top: 15px;
            color: ${colors.white};
            @media (max-width: 576px){
            font-size: 18px;
            }
        }
    }
    
    @media (max-width: 996px){
      .container{
        .row{
          flex-direction: column-reverse !important;
          .col-6{
            min-width: 100% !important;
            .appTitle2{
              text-align: center;
              margin-top: 40px;
            }
           
            .googlePlay{
            width: 100% !important;
            img{
              margin: 0 auto !important;
              display: block !important;
              //width: 100%;
              
            }
              
            }
          }
        }
      }
    }

`;