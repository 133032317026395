import { useTranslation } from 'react-i18next'

import { AppWrapper } from './style'
import Wave from '../../../../assets/images/wave.svg'

// import './main.css'
import GooglePlay from "../../../../assets/images/google-play.svg"
import MobileApp from "../../../../assets/images/mobil-app.svg"

export default function App() {

    const {t, i18n} = useTranslation()

    return (
      <AppWrapper>
        <div className="appInner2 container">
            <div className="row">

                <div className="col-6 d-flex align-items-center">
                    <div>
                        <h2 className="appTitle2">
                            {t("osonsot-client.app2.title")}
                        </h2>
                        <div className="appText2">
                            <p className="appDefText">
                                {t("osonsot-client.app2.subtitle")}
                            </p>
                        </div>
                        <div className="mt-5 googlePlay">
                            <a href="http://play.google.com/store/apps/details?id=uz.unical.osonsot" target="_blank">
                                <img className="" src={GooglePlay} alt=""/>
                            </a>
                        </div>
                    </div>
                </div>
                <div className="col-6  d-flex align-items-center justify-content-center">
                    <img className="mobileApp" src={MobileApp} alt="image"/>
                </div>

            </div>
        </div>
      </AppWrapper>
    )
}