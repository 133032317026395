import { useTranslation } from 'react-i18next'

import { useState } from 'react'
import Slider from "react-slick";

import { TariffWrapper } from './style'
import './main.css'

import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'

export default function Tariff() {

    const settings = {
        className: "center",
        centerMode: true,
        infinite: true,
        centerPadding: "20px",
        slidesToShow: 3,
        speed: 500,
        responsive: [
            {
                breakpoint: 992,
                settings: {
                    slidesToShow: 1.8,
                    slidesToScroll: 3,
                    infinite: true,
                    dots: false
                }
            },
            {
                breakpoint: 768,
                settings: {
                    slidesToShow: 1.3,
                    slidesToScroll: 3,
                    infinite: true,
                    dots: false
                }
            },
            {
                breakpoint: 576,
                settings: {
                    slidesToShow: 1.4,
                    slidesToScroll: 0,
                    infinite: true,
                    dots: false
                }
            },
            {
                breakpoint: 500,
                settings: {
                    slidesToShow: 1.1,
                    slidesToScroll: 0,
                    infinite: true,
                    dots: false
                }
            },
            {
                breakpoint: 380,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 0,
                    infinite: true,
                    dots: false
                }
            }
        ]
    };

    const [timeType, setTimeType] = useState('monthly')
    const [timeType2, setTimeType2] = useState('monthly')
    const [timeType3, setTimeType3] = useState('monthly')
    const [timeType4, setTimeType4] = useState('monthly')
    const [timeType5, setTimeType5] = useState('monthly')
    const [timeType6, setTimeType6] = useState('monthly')

    const { t, i18n } = useTranslation()

    return (
        <TariffWrapper>
            <div className="carouselWrapper container">
                <h2 className="newsSectionTitle container1 mb-3">
                    {t("osonsot-client.tariff.title")}
                </h2>
                <p className="newsSectionSubTitle">
                    {t("osonsot-client.tariff.subtitle")}
                </p>
                <Slider {...settings} className='sliderWrap'>
                    <div>
                        <div className="tariffCard">
                            <p className="tariffCardTitle">
                                {t("osonsot-client.tariff-cards.tariff-card1.title")}
                            </p>
                            <p className="tariffCardSubtitle">
                                {t("osonsot-client.tariff-cards.tariff-card1.subtitle")}
                            </p>
                            <p className="tariffCardPrice">
                                {timeType === "monthly" ? "199 000" : "169 000"}
                            </p>

                            <div className="tariffPeriod d-flex justify-content-between">
                                <p className="monthly tariffPeriodType" onClick={() => setTimeType("monthly")}>{t("osonsot-client.tariff-cards.monthly")}</p>
                                <p className="annually tariffPeriodType" onClick={() => setTimeType("annually")}>{t("osonsot-client.tariff-cards.annually")}</p>
                                <p className={`dynamicBtn ${timeType === "annually" ? "activeRight" : "activeLeft"}`} ></p>
                            </div>

                            <p className="tariffCardOpportunities">{t("osonsot-client.tariff-cards.tariff-card1.defenition1")}</p>

                            <p className="tariffCardBtn">
                                {t("osonsot-client.tariff-cards.button")}
                            </p>
                        </div>
                    </div>
                    <div>
                        <div className="tariffCard">
                            <p className="tariffCardTitle">
                                {t("osonsot-client.tariff-cards.tariff-card2.title")}
                            </p>
                            <p className="tariffCardSubtitle">
                                {t("osonsot-client.tariff-cards.tariff-card2.subtitle")}
                            </p>
                            <p className="tariffCardPrice">
                                {timeType2 === "monthly" ? "499 000" : "419 000"}
                            </p>

                            <div className="tariffPeriod d-flex justify-content-between">
                                <p className="monthly tariffPeriodType" onClick={() => setTimeType2("monthly")}>{t("osonsot-client.tariff-cards.monthly")}</p>
                                <p className="annually tariffPeriodType" onClick={() => setTimeType2("annually")}>{t("osonsot-client.tariff-cards.annually")}</p>
                                <p className={`dynamicBtn ${timeType2 === "annually" ? "activeRight" : "activeLeft"}`} ></p>
                            </div>

                            <p className="tariffCardOpportunities">{t("osonsot-client.tariff-cards.tariff-card2.defenition1")}</p>

                            <p className="tariffCardBtn">
                                {t("osonsot-client.tariff-cards.button")}
                            </p>
                        </div>
                    </div>
                    <div>
                        <div className="tariffCard">
                            <p className="tariffCardTitle">
                                {t("osonsot-client.tariff-cards.tariff-card3.title")}
                            </p>
                            <p className="tariffCardSubtitle">
                                {t("osonsot-client.tariff-cards.tariff-card3.subtitle")}
                            </p>
                            <p className="tariffCardPrice">
                                {timeType3 === "monthly" ? "799 000" : "679 000"}
                            </p>

                            <div className="tariffPeriod d-flex justify-content-between">
                                <p className="monthly tariffPeriodType" onClick={() => setTimeType3("monthly")}>{t("osonsot-client.tariff-cards.monthly")}</p>
                                <p className="annually tariffPeriodType" onClick={() => setTimeType3("annually")}>{t("osonsot-client.tariff-cards.annually")}</p>
                                <p className={`dynamicBtn ${timeType3 === "annually" ? "activeRight" : "activeLeft"}`} ></p>
                            </div>

                            <p className="tariffCardOpportunities">{t("osonsot-client.tariff-cards.tariff-card3.defenition1")}</p>

                            <p className="tariffCardBtn">
                                {t("osonsot-client.tariff-cards.button")}
                            </p>
                        </div>
                    </div>
                    <div>
                        <div className="tariffCard">
                            <p className="tariffCardTitle">
                                {t("osonsot-client.tariff-cards.tariff-card1.title")}
                            </p>
                            <p className="tariffCardSubtitle">
                                {t("osonsot-client.tariff-cards.tariff-card1.subtitle")}
                            </p>
                            <p className="tariffCardPrice">
                                {timeType4 === "monthly" ? "199 000" : "169 000"}
                            </p>

                            <div className="tariffPeriod d-flex justify-content-between">
                                <p className="monthly tariffPeriodType" onClick={() => setTimeType4("monthly")}>{t("osonsot-client.tariff-cards.monthly")}</p>
                                <p className="annually tariffPeriodType" onClick={() => setTimeType4("annually")}>{t("osonsot-client.tariff-cards.annually")}</p>
                                <p className={`dynamicBtn ${timeType4 === "annually" ? "activeRight" : "activeLeft"}`} ></p>
                            </div>

                            <p className="tariffCardOpportunities">{t("osonsot-client.tariff-cards.tariff-card1.defenition1")}</p>

                            <p className="tariffCardBtn">
                                {t("osonsot-client.tariff-cards.button")}
                            </p>
                        </div>
                    </div>
                    <div>
                        <div className="tariffCard">
                            <p className="tariffCardTitle">
                                {t("osonsot-client.tariff-cards.tariff-card2.title")}
                            </p>
                            <p className="tariffCardSubtitle">
                                {t("osonsot-client.tariff-cards.tariff-card2.subtitle")}
                            </p>
                            <p className="tariffCardPrice">
                                {timeType5 === "monthly" ? "499 000" : "419 000"}
                            </p>

                            <div className="tariffPeriod d-flex justify-content-between">
                                <p className="monthly tariffPeriodType" onClick={() => setTimeType5("monthly")}>{t("osonsot-client.tariff-cards.monthly")}</p>
                                <p className="annually tariffPeriodType" onClick={() => setTimeType5("annually")}>{t("osonsot-client.tariff-cards.annually")}</p>
                                <p className={`dynamicBtn ${timeType5 === "annually" ? "activeRight" : "activeLeft"}`} ></p>
                            </div>

                            <p className="tariffCardOpportunities">{t("osonsot-client.tariff-cards.tariff-card2.defenition1")}</p>

                            <p className="tariffCardBtn">
                                {t("osonsot-client.tariff-cards.button")}
                            </p>
                        </div>
                    </div>
                    <div>
                        <div className="tariffCard">
                            <p className="tariffCardTitle">
                                {t("osonsot-client.tariff-cards.tariff-card3.title")}
                            </p>
                            <p className="tariffCardSubtitle">
                                {t("osonsot-client.tariff-cards.tariff-card3.subtitle")}
                            </p>
                            <p className="tariffCardPrice">
                                {timeType6 === "monthly" ? "799 000" : "679 000"}
                            </p>

                            <div className="tariffPeriod d-flex justify-content-between">
                                <p className="monthly tariffPeriodType" onClick={() => setTimeType6("monthly")}>{t("osonsot-client.tariff-cards.monthly")}</p>
                                <p className="annually tariffPeriodType" onClick={() => setTimeType6("annually")}>{t("osonsot-client.tariff-cards.annually")}</p>
                                <p className={`dynamicBtn ${timeType6 === "annually" ? "activeRight" : "activeLeft"}`} ></p>
                            </div>

                            <p className="tariffCardOpportunities">{t("osonsot-client.tariff-cards.tariff-card3.defenition1")}</p>

                            <p className="tariffCardBtn">
                                {t("osonsot-client.tariff-cards.button")}
                            </p>
                        </div>
                    </div>
                </Slider>
            </div>
            <div className="row sliderMedia p-0 m-0">
                <div className="col-6 offset-3 mt-3">
                    <div className="tariffCard">
                        <p className="tariffCardTitle">
                            {t("osonsot-client.tariff-cards.tariff-card1.title")}
                        </p>
                        <p className="tariffCardSubtitle">
                            {t("osonsot-client.tariff-cards.tariff-card1.subtitle")}
                        </p>
                        <p className="tariffCardPrice">
                            {timeType === "monthly" ? "199 000" : "169 000"}
                        </p>

                        <div className="tariffPeriod d-flex justify-content-between">
                            <p className="monthly tariffPeriodType" onClick={() => setTimeType("monthly")}>{t("osonsot-client.tariff-cards.monthly")}</p>
                            <p className="annually tariffPeriodType" onClick={() => setTimeType("annually")}>{t("osonsot-client.tariff-cards.annually")}</p>
                            <p className={`dynamicBtn ${timeType === "annually" ? "activeRight" : "activeLeft"}`} ></p>
                        </div>

                        <div className="paddingMedia">
                            <p className="tariffCardOpportunities">{t("osonsot-client.tariff-cards.tariff-card1.defenition1")}</p>
                        </div>

                        <p className="tariffCardBtn">
                            {t("osonsot-client.tariff-cards.button")}
                        </p>
                    </div>
                </div>
                <div className="col-6 offset-3 mt-3">
                    <div className="tariffCard">
                        <p className="tariffCardTitle">
                            {t("osonsot-client.tariff-cards.tariff-card2.title")}
                        </p>
                        <p className="tariffCardSubtitle">
                            {t("osonsot-client.tariff-cards.tariff-card2.subtitle")}
                        </p>
                        <p className="tariffCardPrice">
                            {timeType2 === "monthly" ? "499 000" : "419 000"}
                        </p>

                        <div className="tariffPeriod d-flex justify-content-between">
                            <p className="monthly tariffPeriodType" onClick={() => setTimeType2("monthly")}>{t("osonsot-client.tariff-cards.monthly")}</p>
                            <p className="annually tariffPeriodType" onClick={() => setTimeType2("annually")}>{t("osonsot-client.tariff-cards.annually")}</p>
                            <p className={`dynamicBtn ${timeType2 === "annually" ? "activeRight" : "activeLeft"}`} ></p>
                        </div>

                        <div className="paddingMedia">
                            <p className="tariffCardOpportunities">{t("osonsot-client.tariff-cards.tariff-card1.defenition1")}</p>
                        </div>

                        <p className="tariffCardBtn">
                            {t("osonsot-client.tariff-cards.button")}
                        </p>
                    </div>
                </div>
                <div className="col-6 offset-3 mt-3">
                    <div className="tariffCard">
                        <p className="tariffCardTitle">
                            {t("osonsot-client.tariff-cards.tariff-card3.title")}
                        </p>
                        <p className="tariffCardSubtitle">
                            {t("osonsot-client.tariff-cards.tariff-card3.subtitle")}
                        </p>
                        <p className="tariffCardPrice">
                            {timeType3 === "monthly" ? "799 000" : "679 000"}
                        </p>

                        <div className="tariffPeriod d-flex justify-content-between">
                            <p className="monthly tariffPeriodType" onClick={() => setTimeType3("monthly")}>{t("osonsot-client.tariff-cards.monthly")}</p>
                            <p className="annually tariffPeriodType" onClick={() => setTimeType3("annually")}>{t("osonsot-client.tariff-cards.annually")}</p>
                            <p className={`dynamicBtn ${timeType3 === "annually" ? "activeRight" : "activeLeft"}`} ></p>
                        </div>

                        <div className="paddingMedia">
                            <p className="tariffCardOpportunities">{t("osonsot-client.tariff-cards.tariff-card1.defenition1")}</p>
                        </div>

                        <p className="tariffCardBtn">
                            {t("osonsot-client.tariff-cards.button")}
                        </p>
                    </div>
                </div>
            </div>
        </TariffWrapper>
    )
}