import styled from "styled-components";
import { colors } from "../../../../assets/styles/colors";

export const UserCardsWrapper = styled.div`
  .userCardsInner {
    /* .title {
      margin-top: 50px;
    } */
    .title,
    .text {
      text-align: center;
      span {
        color: ${colors.mainBlue};
      }
    }
    .text {
      font-size: 20px;
      @media (max-width: 576px) {
        font-size: 18px;
        margin-top: 20px;
      }
    }
    .title {
      @media (max-width: 576px) {
        font-size: 25px;
      }
    }

    .userCardWrapper {
      margin-top: 75px;

      .userCard {
        box-shadow: 0px 0px 4px rgba(62, 177, 200, 0.2);
        border-radius: 8px;
        padding: 24px;
        height: 100% !important;
        box-sizing: border-box;
        transition: all ease 0.3s;
        cursor: pointer;

        &:hover {
          transform: scale(1.05);
          box-shadow: 0px 0px 4px rgba(62, 177, 200, 0.5);
        }
      }

      .userCardTop {
        display: flex;
        align-items: center;

        .userCardTitle {
          font-style: normal;
          font-weight: bold;
          font-size: 20px;
          line-height: 160%;
          margin-left: 24px;
        }
      }

      .userCardBottom {
        margin-top: 32px;
        .userCardText {
          font-size: 16px !important;
        }
      }
    }
  }
`;
